<template>
  <wizard-form v-on="$listeners" v-bind="$attrs">
    <template #content>
      <v-card-text class="text--primary">
        <v-row>
          <v-col
            cols="12"
            md="4"
            class="v-stepper__step__step_img hidden-sm-and-down"
            :style="background"
          >
            <v-img
              height="50%"
              width="100%"
              class="v-stepper__step__step__img_background"
              v-if="!handleWhiteBranding"
            ></v-img>
            <br />
            <v-row v-if="!handleWhiteBranding" justify="center">
              <v-icon color="white darken-2" x-large>
                mdi-account-supervisor
              </v-icon>
            </v-row>
          </v-col>
          <v-col cols="12" md="7" offset-md="1" class="">
            <v-row>
              <v-col cols="12">
                <h1>
                  Información de su Interés sobre Instrucciones de Transferencia
                </h1>
              </v-col>
            </v-row>
            <div class="mt-10">
              <v-row dense>
                <v-col cols="12">
                  <ul class="custom-ul">
                    <li>
                      <h3 class="d-flex flex-row align-center">
                        <v-icon color="primary" style="font-size: 0.9rem;"
                          >mdi-circle</v-icon
                        >
                        <span class="ml-2"> 
                          {{ $t('transfer_info_subtitle_1') }}
                        </span>
                      </h3>
                      <br />
                      <p class="ml-8 ul-text">
                        {{ $t('transfer_info_text_1') }}
                      </p>
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <ul class="custom-ul">
                    <li>
                      <h3 class="d-flex flex-row align-center">
                        <v-icon color="primary" style="font-size: 0.9rem;"
                          >mdi-circle</v-icon
                        >
                        <span class="ml-2">
                          {{ $t('transfer_info_subtitle_2') }}
                        </span>
                      </h3>
                      <br />
                      <div class="ml-8 ul-text">
                        <p>
                          {{ $t('transfer_info_text_2') }}
                        </p>
                        <ul class="custom-ul mt-2">
                          <li
                            v-for="(bank, index) in partnerBanks"
                            :key="index"
                            class="d-flex flex-row align-center"
                          >
                            <v-icon color="primary" style="font-size: 0.9rem;"
                              >mdi-circle</v-icon
                            >
                            <span class="ml-2">
                              {{ bank.prtBankName }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <br />
              <v-row dense>
                <v-col cols="12">
                  <ul class="custom-ul">
                    <li>
                      <h3 class="d-flex flex-row align-center">
                        <v-icon color="primary" style="font-size: 0.9rem;"
                          >mdi-circle</v-icon
                        >
                        <span class="ml-2">
                          {{ $t('transfer_info_subtitle_3') }}
                        </span>
                      </h3>
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <ul class="custom-ul">
                    <li>
                      <h3 class="d-flex flex-row align-center">
                        <v-icon color="primary" style="font-size: 0.9rem;"
                          >mdi-circle</v-icon
                        >
                        <span class="ml-2">
                          {{ $t('transfer_info_subtitle_4') }}
                        </span>
                      </h3>
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
  </wizard-form>
</template>
<script>
import { mapState } from 'vuex'
import WizardForm from '@/components/commons/WizardForm'

export default {
  components: {
    WizardForm
  },
  props: {
    partnerBanks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      items: [{ text: 'transfer_low_cost' }, { text: 'transfer_quick' }]
    }
  },
  computed: {
    ...mapState('language', ['languages', 'idiom']),
    ...mapState('auth', ['user']),
    ...mapState('setting', ['whiteBranding']),

    handleWhiteBranding() {
      return this.user?.broker && this.whiteBranding
    },

    background() {
      return this.handleWhiteBranding ? `background-image: url('${this.user.broker.img_background}')` : `background-image: url('${require('@/assets/AV_004_inicio_portal_clientes_sinletra.jpg')}')`
    }
  }
}
</script>
<style scoped>
h1 {
  line-height: 1.5;
}
.v-stepper__step__step_img {
  padding: 60px;
  height: 380px;
  border-top-right-radius: 25%;
  border-bottom-left-radius: 25%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.v-stepper__step__step__img_background {
  margin-top: 10%;
  background: url('../../../assets/AV_003_logo_header.svg');
  background-repeat: no-repeat;
  background-size: 100% !important;
}

.v-stepper__step__step__link {
  text-align: center;
  font: normal normal normal 14px/20px Lato;
  letter-spacing: 0px;
  color: #00559e;
  opacity: 1;
}

.v-stepper__step__text__grey {
  text-align: center;
  font: normal normal normal 14px/20px Lato;
  letter-spacing: 0px;
  color: #686f8d;
  opacity: 1;
}

.v-stepper__step__step__text {
  font: normal normal normal 16px/20px Lato;
  letter-spacing: 0px;
  color: #212529;
  opacity: 1;
}

.v-stepper__step__text__title {
  text-align: left;
  font: normal normal normal 44px/52px Lato;
  letter-spacing: 0px;
  color: #00559e;
  text-transform: uppercase;
  opacity: 1;
}

.v-stepper__step_text_subtitle {
  text-align: left;
  font: normal normal normal 22px/40px Lato;
  letter-spacing: 0px;
  color: #039be5;
  text-transform: uppercase;
  opacity: 1;
}

.v-stepper-border {
  border-bottom: 2px solid #ea5716;
  opacity: 1;
}

.custom-ul {
  list-style: none;
}

.ul-text {
  font-size: 1.1rem;
}

@media (max-width: 979px) {
  .v-stepper__step__text__grey {
    font: normal normal normal 12px/20px Lato;
  }

  .v-stepper__step__text__title {
    font: normal normal normal 16px/18px Lato;
  }

  .v-stepper__step_text_subtitle {
    font: normal normal normal 10px/14px Lato;
  }
}
</style>
