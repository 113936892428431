<template>
  <wizard-form v-on="$listeners" v-bind="$attrs">
    <template #content>
      <v-row dense>
        <v-col cols="12">
          <div>
            <h1 class="primary--text  font-weight-bold">
              {{ $t('transfer_local_panama') }}
            </h1>
            <div class="mt-2 mb-4">
              <hr class="rounded primary" color="primary"/>
              <hr class="primary" size="1" color="primary" />
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_alias')"
            rules="required|max:50"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="`${$t('transfer_alias')} *`"
              dense
              autocomplete="nope"
              v-model="permanentInstruction.alias"
              :error-messages="errors[0]"
              maxlength="50"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_account_type')"
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              v-model="permanentInstruction.accountType"
              :items="items"
              :label="`${$t('transfer_account_type')} *`"
              :error-messages="errors[0]"
              dense
              return-object
              item-text="t"
              @input="handleAccountType"
            >
              <template #item="{ item }">
                <span>{{ $t(item.t) }}</span>
              </template>

              <template #selection="{ item }">
                <span>{{ $t(item.t) }}</span>
              </template>
            </v-select>
          </ValidationProvider>
        </v-col>
        <v-col
          :cols="isThirdParty || isExecutive ? 12 : 11"
          :md="isThirdParty || isExecutive ? 6 : 5"
        >
          <ValidationProvider
            :name="$t('transfer_name')"
            rules="required|max:50"
            v-slot="{ errors }"
          >
            <v-text-field
              ref="beneficiary"
              :label="`${$t('transfer_name')} *`"
              dense
              autocomplete="nope"
              v-model="permanentInstruction.beneficiary"
              :error-messages="errors[0]"
              :disabled="!isThirdParty || isExecutive"
              maxlength="50"
              @input="handleInputs"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="1" v-if="!isThirdParty && !isExecutive">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn icon v-on="on" @click="handleUnlock">
                <v-icon>mdi-lock-open-variant</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('modify_beneficiary') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider
            ref="email"
            :name="$t('transfer_email')"
            rules="email|confirmed:correo|max:50"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="$t('transfer_email')"
              dense
              autocomplete="nope"
              v-model="permanentInstruction.bnfEmail"
              :error-messages="errors[0]"
              maxlength="50"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_confirm_email')"
            rules="email|max:50"
            vid="correo"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="$t('transfer_confirm_email')"
              dense
              autocomplete="nope"
              v-model="permanentInstruction.bnfEmailConfirm"
              :error-messages="errors[0]"
              maxlength="50"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col v-if="permanentInstruction.accountType.value == 'nfiAccount'" cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_financial_institution_of_beneficiary')"
            rules="required|max:50"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="`${$t('transfer_financial_institution_of_beneficiary')} *`"
              dense
              autocomplete="nope"
              v-model="permanentInstruction.bnfBankName"
              :error-messages="errors[0]"
              maxlength="50"
            />
          </ValidationProvider>
        </v-col>
        <v-col v-else cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_financial_institution_of_beneficiary')"
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              v-model="partnerBank"
              :items="nationalBanks"
              item-text="prtBankName"
              item-value="prtBankName"
              @input="handlePartnerBank"
              :label="`${$t('transfer_financial_institution_of_beneficiary')} *`"
              :error-messages="errors[0]"
              dense
            />
          </ValidationProvider>
        </v-col>
        <v-col v-if="anotherBank == true" cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_other_beneficiary_institution')"
            rules="required|max:50"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="`${$t('transfer_other_beneficiary_institution')} *`"
              dense
              autocomplete="nope"
              v-model="permanentInstruction.bnfBankName"
              :error-messages="errors[0]"
              maxlength="50"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_account_number_iban')"
            rules="required|alpha_num|max:50"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="`${$t('transfer_account_number_iban')} *`"
              dense
              autocomplete="nope"
              v-model="permanentInstruction.bnfBankAccNumber"
              :error-messages="errors[0]"
              @input="handleInputs"
              maxlength="50"
              v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row v-if="permanentInstruction.accountType.value == 'nfiAccount'" dense>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_receiving_bank')"
            rules="required|max:50"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="`${$t('transfer_receiving_bank')} *`"
              dense
              autocomplete="nope"
              v-model="permanentInstruction.nfiName"
              :error-messages="errors[0]"
              maxlength="50"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_account_receiving_bank')"
            rules="required|alpha_num|max:50"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="`${$t('transfer_account_receiving_bank')} *`"
              dense
              autocomplete="nope"
              v-model="permanentInstruction.nfiAccountNumber"
              :error-messages="errors[0]"
              maxlength="50"
              v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="ABA / SWIFT"
            dense
            autocomplete="nope"
            v-model="permanentInstruction.bnfBankABA"
            maxlength="50"
          />
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_city')"
            rules="max:50"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="$t('transfer_city')"
              dense
              autocomplete="nope"
              v-model="permanentInstruction.bnfBankCity"
              :error-messages="errors[0]"
              maxlength="50"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_country')"
            rules=""
            v-slot="{ errors }"
          >
            <v-select
              v-model="permanentInstruction.bnfBankCountry"
              :items="countries"
              item-text="countryName"
              item-value="countryName"
              :label="$t('transfer_country')"
              :error-messages="errors[0]"
              @input="selectCountry($event, 'bnfBankCountryCode')"
              dense
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            :label="$t('transfer_observations')"
            dense
            autocomplete="nope"
            v-model="permanentInstruction.bnfReference"
            :disabled="permanentInstruction.accountType.value == 'nfiAccount'"
            maxlength="128"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :label="$t('transfer_instructions')"
            dense
            autocomplete="nope"
            v-model="permanentInstruction.avsInternalRemark"
            maxlength="128"
          />
        </v-col>
      </v-row>
    </template>
  </wizard-form>
</template>
<script>
import WizardForm from '@/components/commons/WizardForm'
import { mapState } from 'vuex'

export default {
  components: {
    WizardForm
  },
  props: {
    permanentInstruction: {
      type: Object,
      required: true
    },
    partnerBanks: {
      type: Array,
      required: true
    },
    countries: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      anotherBank: false,
      partnerBank: '',
      items: [
        { value: 'localCheckingAccount', t: 'transfer_account_type_1' },
        { value: 'localSavingsAccount', t: 'transfer_account_type_2' },
        { value: 'nfiAccount', t: 'transfer_account_type_3' }
      ],
      banks: ['Panacor', 'Banesco'],
      isThirdParty: false
    }
  },
  computed: {
    nationalBanks() {
      let banks = this.partnerBanks.filter(bank => bank.prtBankType == 'local')
      banks.push({ prtBankName: this.$t('transfer_other') })
      return banks
    },
    isExecutive() {
      return this.user?.authorityLevelsCode === 1
    },
    ...mapState('account', ['account', 'accountData']),
    ...mapState('auth', ['user'])
  },
  methods: {
    setBeneficiary() {
      // Account By Default (SameName)
      if (!this.isThirdParty) {
        const fullName = this.account.firstname + ' ' + this.account.lastname
        this.permanentInstruction.beneficiary = fullName

        if (this.accountData) {
          const { email } = this.accountData
          this.permanentInstruction.bnfEmail = email
          this.permanentInstruction.bnfEmailConfirm = email

          // Validate Emails
          this.$refs.email.validate()
          // Set focus
          this.$nextTick(() => this.$refs.beneficiary.focus())
        }
      }
    },

    handleAccountType() {
      this.partnerBank = ''
      if (this.permanentInstruction.accountType.value == 'nfiAccount') {
        this.$emit('changeAccType')
      } else {
        this.$emit('clear')
      }
      this.setBeneficiary()
    },
    handleInputs() {
      if (this.permanentInstruction.accountType.value == 'nfiAccount')
        this.$emit('changeAccType')
    },
    selectCountry(country, model) {
      const selectedCountry = this.countries.find(item => item.countryName == country)
      this.$emit('selectCountry', { model, code: selectedCountry.countryCode })
    },
    handlePartnerBank(bank) {
      if (bank == this.$t('transfer_other')) {
        this.anotherBank = true
        this.$emit('clearBank')
      } else {
        this.anotherBank = false
        this.$emit('selectPartnerBank', bank)
      }
    },
    async handleUnlock() {
      const text =
        this.$t('warning_instructions_payments_to_third_parties_1') +
        ' ' +
        this.$t('warning_instructions_payments_to_third_parties_2')

      const params = {
        text,
        title: this.$t('twofactor_modal_warning_title'),
        showClose: false,
        persistent: true,
        actions: {
          true: {
            text: this.$t('btn_accept'),
            color: 'primary'
          }
        }
      }

      await this.$dialog.info(params)
      this.isThirdParty = true
      this.permanentInstruction.beneficiary = ''
      this.permanentInstruction.bnfEmail = ''
      this.permanentInstruction.bnfEmailConfirm = ''
      this.permanentInstruction.isThirdParty = this.isThirdParty
      // Set focus
      this.$nextTick(() => this.$refs.beneficiary.focus())
    }
  }
}
</script>
<style scoped>
.v-stepper__step__step__paragraf__ligth {
  text-align: left;
  font: normal normal normal 14px/16px Lato;
  letter-spacing: 0px;
  color: #686f8d;
  opacity: 1;
}
.v-stepper__step__step_subtitles__blacks {
  text-align: left;
  font: normal normal bold 16px/38px Lato;
  letter-spacing: 0px;
  color: #212529;
  opacity: 1;
}
@media (max-width: 979px) {
  .v-stepper__step__step_subtitles__blacks {
    font: normal normal bold 14px/38px Lato;
  }
}

.main-container {
  line-height: normal;
}

hr {
  margin: 0;
  padding: 0;
}

hr.rounded {
  border-top: 2px solid;
  width: 400px;
}
</style>
