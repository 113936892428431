var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wizard-form',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('h1',{staticClass:"primary--text  font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('transfer_internal_account'))+" ")]),_c('div',{staticClass:"mt-2 mb-4"},[_c('hr',{staticClass:"rounded primary",attrs:{"color":"primary"}}),_c('hr',{staticClass:"primary",attrs:{"size":"1","color":"primary"}})])])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_alias'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('transfer_alias')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.alias),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "alias", $$v)},expression:"permanentInstruction.alias"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_account_code'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('transfer_account_code')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfBankAccNumber),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankAccNumber", $$v)},expression:"permanentInstruction.bnfBankAccNumber"}})]}}])})],1),_c('v-col',{attrs:{"cols":_vm.isThirdParty || _vm.isExecutive ? 12 : 11,"md":_vm.isThirdParty || _vm.isExecutive ? 6 : 5}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_name'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"beneficiary",attrs:{"dense":"","autocomplete":"nope","label":((_vm.$t('transfer_name')) + " *"),"error-messages":errors[0],"disabled":!_vm.isThirdParty,"maxlength":"50"},model:{value:(_vm.permanentInstruction.beneficiary),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "beneficiary", $$v)},expression:"permanentInstruction.beneficiary"}})]}}])})],1),(!_vm.isThirdParty && !_vm.isExecutive)?_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.handleUnlock}},on),[_c('v-icon',[_vm._v("mdi-lock-open-variant")])],1)]}}],null,false,3953111920)},[_c('span',[_vm._v(_vm._s(_vm.$t('modify_beneficiary')))])])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('transfer_observations'),"dense":"","autocomplete":"nope","maxlength":"128"},model:{value:(_vm.permanentInstruction.bnfReference),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfReference", $$v)},expression:"permanentInstruction.bnfReference"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('transfer_instructions'),"dense":"","autocomplete":"nope","maxlength":"128"},model:{value:(_vm.permanentInstruction.avsInternalRemark),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "avsInternalRemark", $$v)},expression:"permanentInstruction.avsInternalRemark"}})],1)],1)]},proxy:true}])},'wizard-form',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }