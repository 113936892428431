<template>
  <base-form-modal
    :title="`${$t('transfer_certify_modal_title')} ...${lastDigits}`"
    :loading="loading"
    @close="$emit('submit', null)"
    @submit="handleSubmit"
    :textSave="$t('btn_certify')"
  >
    <template #content>
      <v-row dense>
        <v-col cols="12">
          <ValidationProvider
            :name="$t('transfer_index_account')"
            rules="required|alpha_num|max:50"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="$t('transfer_index_account')"
              v-model="bnfBankAccNumber"
              :error-messages="errors[0]"
              v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
    </template>
  </base-form-modal>
</template>

<script>
import BaseFormModal from '@/components/commons/BaseFormModal'
import CertifyTransferInstructionsService from '@/services/certifyTransferInstruction'

import { mapState } from 'vuex'

export default {
  components: { BaseFormModal },
  props: {
    transferInstructionId: {
      type: String,
      require: true
    },
    bnfBankAccountNumber: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      loading: false,
      bnfBankAccNumber: ''
    }
  },
  computed: {
    ...mapState('account', ['account']),
    lastDigits() {
      return this.bnfBankAccountNumber.substring(this.bnfBankAccountNumber.length - 4)
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        const params = {
          transferInstructionId: this.transferInstructionId,
          bnfBankAccNumber: this.bnfBankAccNumber
        }
        const data = await CertifyTransferInstructionsService.certify(
          this.account.code,
          params
        )
        if (data.statusCode == 200 && data.success) {
          this.$dialog.notify.success(this.$t('transfer_certify_success'))
          this.$emit('submit', true)
        } else {
          this.$dialog.notify.error(this.$t('transfer_certify_error'))
        } 
      } catch (error) {
        console.log(error)
        this.$dialog.notify.error(this.$t('transfer_certify_error'))
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style></style>
