<template>
  <wizard-form :disabledNext="!accordance" v-on="$listeners" v-bind="$attrs">
    <template #content>
      <v-row>
        <v-col cols="12">
          <div>
            <h1 class="primary--text  font-weight-bold">
              {{ $t('transfer_summary') }}
            </h1>
            <div class="mt-2">
              <hr class="rounded primary" color="primary"/>
              <hr class="primary" size="1" color="primary" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row dense class="mb-4 ">
        <v-col cols="12">
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold"
                      >{{ $t('transfer_alias') }}</v-list-item-title
                    >
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon small class="mr-sm-5 d-none d-sm-flex" v-text="'mdi-chevron-right'" color="blue darken-4"/>                    
                    <v-list-item-subtitle class="ml-sm-5">{{
                      permanentInstruction.alias
                    }}</v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="blue lighten-4"></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold"
                      >{{ $t('transfer_name') }}</v-list-item-title
                    >
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon small class="mr-sm-5 d-none d-sm-flex" v-text="'mdi-chevron-right'" color="blue darken-4"/>
                    <v-list-item-subtitle class="ml-sm-5">{{
                      permanentInstruction.beneficiary
                    }}</v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="blue lighten-4"></v-divider>
            <v-list-item v-if="permanentInstruction.trType !== 'avsInternal'">
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold"
                      >{{ $t('transfer_beneficiary_data') }}</v-list-item-title
                    >
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon small class="mr-sm-5 d-none d-sm-flex" v-text="'mdi-chevron-right'" color="blue darken-4"/>
                    <v-list-item-subtitle class="ml-sm-5">
                      <span v-if="permanentInstruction.bnfEmail">{{ permanentInstruction.bnfEmail}}</span>
                      <span v-if="permanentInstruction.bnfPhone">{{ permanentInstruction.bnfPhone}} </span> 
                      <span v-if="permanentInstruction.bnfAddres1">{{ permanentInstruction.bnfAddres1 }} {{ permanentInstruction.bnfAddres2 }} - </span> 
                      <span v-if="permanentInstruction.bnfCountry">{{ permanentInstruction.bnfCountry }} {{ permanentInstruction.bnfState }} {{ permanentInstruction.bnfCity }}, {{ permanentInstruction.bnfZipcode }}</span>
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="permanentInstruction.trType !== 'avsInternal'" class="blue lighten-4"></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold"
                      >{{ $t('transfer_account_type') }}</v-list-item-title
                    >
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon small class="mr-sm-5 d-none d-sm-flex" v-text="'mdi-chevron-right'" color="blue darken-4"/>
                    <v-list-item-subtitle class="ml-sm-5">{{
                      $t(permanentInstruction.accountType.t)
                    }}</v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="blue lighten-4"></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold"
                      >{{ $t('transfer_financial_institution_of_beneficiary') }}</v-list-item-title
                    >
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon small class="mr-sm-5 d-none d-sm-flex" v-text="'mdi-chevron-right'" color="blue darken-4"/>
                    <v-list-item-subtitle class="ml-sm-5">
                      {{ permanentInstruction.bnfBankName }} - {{ permanentInstruction.bnfBankAccNumber }}
                      <span v-if="permanentInstruction.accountType.value !== 'nfiAccount'">
                        <span v-if="permanentInstruction.bnfBankABA">ABA/SWIFT {{ permanentInstruction.bnfBankABA }} </span>
                        <span v-if="permanentInstruction.bnfBankAddres1">- {{ permanentInstruction.bnfBankAddres1 }} {{ permanentInstruction.bnfBankAddres2 }}</span>
                        <span v-if="permanentInstruction.bnfBankCountry"> {{ permanentInstruction.bnfBankCountry }} {{ permanentInstruction.bnfBankState }} {{ permanentInstruction.bnfBankCity }}</span>
                      </span> 
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="blue lighten-4"></v-divider>
            <v-list-item v-if="permanentInstruction.nfiName">
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold"
                      >{{ $t('transfer_receiving_bank') }}</v-list-item-title
                    >
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon small class="mr-sm-5 d-none d-sm-flex" v-text="'mdi-chevron-right'" color="blue darken-4"/>
                    <v-list-item-subtitle class="ml-sm-5">
                    {{ permanentInstruction.nfiName }} - {{ permanentInstruction.nfiAccountNumber }} <span v-if="permanentInstruction.bnfBankABA">- ABA/SWIFT {{ permanentInstruction.bnfBankABA }} -</span> {{ permanentInstruction.bnfBankCity }} {{ permanentInstruction.bnfBankCountry }}
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="permanentInstruction.nfiName" class="blue lighten-4"></v-divider>
            <v-list-item v-if="permanentInstruction.intermBankIsUsed">
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold"
                      >{{ $t('transfer_intermediary') }}</v-list-item-title
                    >
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon small class="mr-sm-5 d-none d-sm-flex" v-text="'mdi-chevron-right'" color="blue darken-4"/>
                    <v-list-item-subtitle class="ml-sm-5">
                      {{ permanentInstruction.intermBankName }} - {{ permanentInstruction.intermBankAccNumber }} 
                      <span v-if="permanentInstruction.intermBankABA">ABA/SWIFT {{ permanentInstruction.intermBankABA }} </span>
                      <span v-if="permanentInstruction.intermBankAddres1">- {{ permanentInstruction.intermBankAddres1 }} {{ permanentInstruction.intermBankAddres2 }}</span>
                      <span v-if="permanentInstruction.intermBankCountry"> {{ permanentInstruction.intermBankCountry }} {{ permanentInstruction.intermBankState }} {{ permanentInstruction.intermBankCity }}</span>
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="permanentInstruction.intermBankIsUsed" class="blue lighten-4"></v-divider>
            <v-list-item v-if="permanentInstruction.intermBankIsUsed">
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold"
                      >{{ $t('transfer_intermediary_observations') }}</v-list-item-title
                    >
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon small class="mr-sm-5 d-none d-sm-flex" v-text="'mdi-chevron-right'" color="blue darken-4"/>
                    <v-list-item-subtitle class="ml-sm-5">
                      {{ permanentInstruction.intermReference }}
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="permanentInstruction.intermBankIsUsed" class="blue lighten-4"></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold"
                      >{{ $t('transfer_observations') }}</v-list-item-title
                    >
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon small class="mr-sm-5 d-none d-sm-flex" v-text="'mdi-chevron-right'" color="blue darken-4"/>
                    <v-list-item-subtitle class="ml-sm-5">{{
                      permanentInstruction.bnfReference
                    }}</v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="blue lighten-4"></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-list-item-title class="font-weight-bold"
                      >{{ $t('transfer_instructions') }}</v-list-item-title
                    >
                  </v-col>
                  <v-col cols="12" sm="9" class="d-flex">
                    <v-icon small class="mr-sm-5 d-none d-sm-flex" v-text="'mdi-chevron-right'" color="blue darken-4"/>
                    <v-list-item-subtitle class="ml-sm-5">{{
                      permanentInstruction.avsInternalRemark
                    }}</v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="blue lighten-4"></v-divider>
          </v-list>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-checkbox
            v-model="accordance"
          >
            <template v-slot:label>
              <div>
                <span class="font-weight-bold">{{ $t('transfer_accordance_title') }}: </span>
                <span>{{ $t('transfer_accordance_authorization') }}</span> <br>
                <span>{{ $t('transfer_accordance_agreement') }}</span>
              </div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
    </template>
  </wizard-form>
</template>
<script>
import WizardForm from '@/components/commons/WizardForm'
export default {
  components: {
    WizardForm
  },
  props: {
    permanentInstruction: {
      type: [Object],
      require: true
    },
  },
  data() {
    return {
      accordance: false,
      stepFour: {
        adress: ['Address 1', 'Address 2'],
        name: 'Name',
        city: 'City',
        codePostal: 'Zip Code',
        observation: 'Observations',
        country: 'Country',
        state: 'State',
        creditPost: 'Back Credit'
      }
    }
  }
}
</script>
<style scoped>
.main-container {
  line-height: normal;
}

hr {
  margin: 0;
  padding: 0;
}

hr.rounded {
  border-top: 2px solid;
  width: 250px;
}
</style>
