<template>
  <wizard-form v-on="$listeners" v-bind="$attrs">
    <template #content>
    <v-row dense>
      <v-col cols="12">
        <div>
          <h1 class="primary--text  font-weight-bold">
            {{ $t('transfer_instruction_type') }}
          </h1>
          <div class="mt-2 mb-4">
            <hr class="rounded primary" color="primary"/>
            <hr class="primary" size="1" color="primary" />
          </div>
        </div>
      </v-col>
      <v-col>
        <ValidationProvider
          name="Account Type"
          rules="required"
          v-slot="{ errors }"
        >
          <v-radio-group @change="changeType" v-model="permanentInstruction.trType" :error-messages="errors[0]">
            <v-radio :label="$t('transfer_local_panama')" value="local"></v-radio>
            <v-radio
              :label="$t('transfer_international_account')"
              value="international"
            ></v-radio>
            <v-radio :label="$t('transfer_internal_account')" value="avsInternal"></v-radio>
          </v-radio-group>
        </ValidationProvider>
      </v-col>
    </v-row>
    </template> 
  </wizard-form>
</template>
<script>
import WizardForm from '@/components/commons/WizardForm'
export default {
  components: {
    WizardForm
  },
  props: {
    permanentInstruction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      instructionType: ''
    }
  },
  methods: {
    changeType() {
      this.$emit('changeInstructionType')
    }
  }
}
</script>
<style scoped>
.v-stepper__step__step__paragraf__ligth {
  text-align: left;
  font: normal normal normal 14px/16px Lato;
  letter-spacing: 0px;
  color: #686f8d;
  opacity: 1;
}
.v-stepper__step__step_subtitles__blacks {
  text-align: left;
  font: normal normal bold 16px/38px Lato;
  letter-spacing: 0px;
  color: #212529;
  opacity: 1;
}
@media (max-width: 979px) {
  .v-stepper__step__step_subtitles__blacks {
    font: normal normal bold 14px/38px Lato;
  }
}

.main-container {
  line-height: normal;
}

hr {
  margin: 0;
  padding: 0;
}

hr.rounded {
  border-top: 2px solid;
  width: 350px;
}
</style>
